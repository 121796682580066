<template>
  <el-main>
    <el-button style="margin-bottom: 15px" type="primary" size="small" @click="add">添加目录</el-button>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="课程标题：">
        <el-input size="small" placeholder="请输入课程标题" v-model="searchForm.title"></el-input>
      </el-form-item>
      <el-form-item label="课程目录标题：">
        <el-input size="small" placeholder="请输入课程目录标题" v-model="searchForm.caption"></el-input>
      </el-form-item>
      <el-form-item label="课程类型：">
        <el-select size="small" v-model="searchForm.is_try" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="试看" :value="1"></el-option>
          <el-option label="正式" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="search">搜索</el-button>
        <el-button size="small" type="text" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="sort" label="排序" width="75" align="center"></el-table-column>
      <el-table-column prop="title" label="课程标题" align="center"></el-table-column>
      <el-table-column prop="caption" label="课程目录标题" align="center"></el-table-column>
      <el-table-column prop="class_time" label="课程时长" align="center"></el-table-column>
      <el-table-column prop="is_try" label="课程类型" align="center">
        <template slot-scope="scope">
          {{ scope.row.is_try == 1 ? '试看' : '正式' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="del(scope.row.id)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="添加/编辑目录" :visible.sync="showAdd" width="650px">
      <el-form :model="addForm" :rules="rules" ref="form" label-width="120px">
        <el-form-item label="所属课程标题：" prop="course_id">
          <el-select clearable v-model="addForm.course_id" placeholder="请选择">
            <el-option v-for="item in courseList" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <el-input v-model="addForm.sort"></el-input>
        </el-form-item>
      
        <el-form-item label="课程目录标题：" prop="caption">
          <el-input v-model="addForm.caption"></el-input>
        </el-form-item>
        <el-form-item label="是否试看：">
          <el-radio-group v-model="addForm.is_try">
            <el-radio :label="1">试看</el-radio>
            <el-radio :label="2">正式</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传视频：" prop="video">
          <ReadyUploadSource @getSource="val => (addForm.video = val.path)" @removeThis="addForm.video = ''" :path="addForm.video" type="video"></ReadyUploadSource>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAdd = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmAdd">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
  components: {
    ReadyUploadSource,
    Paging,
  },
  data() {
    return {
      list: [],
      courseList: [],
      total: 0,
      searchForm: {
        page: 1,
        rows: 10,
        title: '',
        caption: '',
        is_try: '',
      },
      addForm: {
        course_id: '',
        sort: '',
        is_try: 1,
        caption: '',
        video: '',
      },
      showAdd: !1,
      rules: {
        course_id: { required: true, message: '请选择所属课程', trigger: 'blur' },
        sort: { required: true, message: '请填写目录排序', trigger: 'blur' },
        caption: { required: true, message: '请填写目录标题', trigger: 'blur' },
        video: { required: true, message: '请添加视频', trigger: 'blur' },
      },
    };
  },
  created() {
    this.getList();
    this.getCourse();
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    search() {
      this.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        title: '',
        caption: '',
        is_try: '',
      };
      this.getList();
    },
    getCourse() {
      this.$axios
        .post(this.$api.beauty.courseList, {
          rows: 10000,
        })
        .then(res => {
          if (res.code == 0) {
            this.courseList = res.result.list;
          }
        });
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (!searchForm.is_try) delete searchForm.is_try;
      this.$axios.post(this.$api.beauty.courseCatalogList, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    add() {
      this.showAdd = !0;
      this.addForm = {
        course_id: '',
        sort: '',
        is_try: 1,
        caption: '',
        video: '',
      };
    },
    edit(row) {
      this.showAdd = !0;
      this.addForm.course_id = row.course_id;
      this.addForm.sort = row.sort;
      this.addForm.is_try = row.is_try;
      this.addForm.caption = row.caption;
      this.addForm.video = row.video;
      this.addForm.id = row.id;
    },
    del(id) {
      this.$confirm('请确认是否删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.beauty.courseCatalogDel, {
            id: id,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('删除成功');
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    comfirmAdd() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let addForm = this.addForm;
          this.$axios.post(addForm.id ? this.$api.beauty.courseCatalogEdit : this.$api.beauty.courseCatalogAdd, addForm).then(res => {
            if (res.code == 0) {
              this.showAdd = !1;
              this.getList();
              this.$message.success(addForm.id ? '编辑成功' : '添加成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>